import * as React from 'react';
export const TripletexLogomarkLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={36}
    fill="none"
    viewBox="0 0 25 36"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#TripletexLogomarkLogo__a)">
      <path
        fill="#58B3CD"
        d="M18.4428 18.5884v-1.0361h6.0978a6.5169 6.5169 0 0 0-1.8566-4.0455 6.4591 6.4591 0 0 0-8.0461-.8968 6.4588 6.4588 0 1 0 9.9027 5.9792l-6.0978-.0008Z"
      />
      <path
        fill="#1AB96D"
        d="M6.0914 29.8998h1.0358v6.0979a6.5175 6.5175 0 0 0 4.0455-1.8567 6.4591 6.4591 0 0 0 .8968-8.0461 6.459 6.459 0 0 0-10.9796.1271 6.4589 6.4589 0 0 0 5.0004 9.7757l.001-6.0979Z"
      />
      <path
        fill="#010A59"
        d="m7.0705 6.5668-.7232-.7232 4.2996-4.2996a6.457 6.457 0 0 0-8.7557 9.4789 6.4567 6.4567 0 0 0 8.9471.1773 6.4571 6.4571 0 0 0 .5318-8.933L7.0705 6.5668Z"
      />
    </g>
    <defs>
      <clipPath id="TripletexLogomarkLogo__a">
        <path fill="#fff" d="M0 0h25v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
