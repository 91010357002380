import { createNextState } from '@reduxjs/toolkit';
import { getType } from 'typesafe-actions';
import * as uuid from 'uuid';
import { getCompanyType, makeIdWithPrefix } from '../../utils';
import type { PersonInfo } from './actions';
import {
  type Company,
  type EditorAction,
  type EditorReducerState,
  type Person,
  type Role,
  type RoleAssignment,
  actions,
} from './';

function makeInitialState(id?: string): EditorReducerState {
  if (!id) {
    id = makeIdWithPrefix();
  }

  const founderId = uuid.v1();
  const capital = '30000';
  const state: EditorReducerState = {
    dirty: false,
    readOnly: false,
    hasSubmittedEmail: false,
    hasTrackedFormOwnerInfo: false,
    equityAssignmentDirty: false,
    ownershipType: 'single',
    id,
    formOwner: founderId,
    formOwnerAsAddress: true,
    ceo: founderId,
    hasEditableBoard: false,
    coreInfo: {
      name: '',
      foundingDate: new Date().toISOString(),
      capital,
      shareCount: capital,
      purpose: undefined,
      verbosePurpose: undefined,
      activityId: undefined,
      activityCategoryId: undefined,
      companyType: 'UNSPECIFIED',
      cadasterAddress: undefined,
      postalCode: '',
      address: '',
      willHaveEmployees: 'NO',
      orgTransfer: undefined,
    },
    people: {
      [founderId]: newPerson(founderId),
    },
    companies: {},
    roles: [
      {
        id: founderId,
        role: 'chair',
      },
    ],
    equity: {
      [founderId]: capital,
    },
    pagesMarkedForValidation: [],
  };

  return state;
}

export function newPerson(id: string = uuid.v1()): Person {
  return {
    id,
    name: '',
    pNum: '',
    address: '',
    postalCode: '',
    cadasterAddress: undefined,
    phone: '',
    email: '',
  };
}

export function newCompany(id: string = uuid.v1()): Company {
  return {
    id,
    name: '',
    orgId: '',
    signatories: [],
  };
}

function setPersonInfo(draft: EditorReducerState, inPerson: PersonInfo): void {
  const { id } = inPerson;
  const person = draft.people[id] || newPerson(id);
  Object.assign(person, inPerson);
  draft.people[id] = person;
  draft.dirty = true;
  if (draft.formOwnerAsAddress && id === draft.formOwner) {
    Object.assign(draft.coreInfo, {
      address: person.address,
      postalCode: person.postalCode,
    });
  }
}

const reducer = createNextState(
  (draft: EditorReducerState, action: EditorAction) => {
    switch (action.type) {
      case getType(actions.restoreSession): {
        // fixme: type the save/load format?
        return { ...action.payload, dirty: false };
      }

      case getType(actions.setReadOnly): {
        draft.readOnly = true;
        return;
      }

      case getType(actions.removeAsOwner): {
        delete draft.equity[action.payload];
        if (action.payload in draft.companies) {
          delete draft.companies[action.payload];
        }
        draft.dirty = true;
        return;
      }

      case getType(actions.setPersonInfo): {
        const { id } = action.payload;
        const person = draft.people[id] || newPerson(id);
        Object.assign(person, action.payload);
        draft.people[id] = person;
        draft.dirty = true;
        if (draft.formOwnerAsAddress && id === draft.formOwner) {
          Object.assign(draft.coreInfo, {
            address: person.address,
            postalCode: person.postalCode,
          });
        }
        return;
      }

      case getType(actions.setCompanyInfo): {
        const { id } = action.payload;
        const company = draft.companies[id] || newCompany(id);
        Object.assign(company, action.payload);
        draft.companies[id] = company;
        draft.dirty = true;
        return;
      }

      case getType(actions.setCoreInfo): {
        Object.assign(draft.coreInfo, action.payload);
        draft.coreInfo.shareCount = draft.coreInfo.capital;
        // fixme: The following feels off. How it syncs the
        // share count
        if (Object.keys(draft.equity).length === 1) {
          const id = Object.keys(draft.equity)[0];
          draft.equity[id] = draft.coreInfo.capital;
        }
        draft.dirty = true;
        return;
      }

      case getType(actions.assignRole): {
        const { id, role } = action.payload;
        draft.roles = performAssignRole(draft.roles, id, role);
        draft.dirty = true;
        return;
      }

      case getType(actions.assignCeo): {
        draft.ceo = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.removeRole): {
        const { id, role } = action.payload;
        draft.roles = doRemoveRole(draft.roles, id, role);
        draft.dirty = true;
        return;
      }

      case getType(actions.removeFromAllRoles): {
        const id = action.payload;
        draft.roles = draft.roles.filter(
          roleAssignment => roleAssignment.id !== id,
        );
        draft.dirty = true;
        return;
      }

      case getType(actions.setEquity): {
        const { id, equity } = action.payload;
        draft.equity[id] = equity;
        draft.dirty = true;
        return;
      }

      case getType(actions.setEquityDirty): {
        draft.equityAssignmentDirty = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.setEmailSubmitted): {
        draft.hasSubmittedEmail = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.setFormOwnerInfoTracked): {
        draft.hasTrackedFormOwnerInfo = true;
        draft.dirty = true;
        return;
      }

      case getType(actions.balanceEquity): {
        const ownerIds = Object.keys(draft.equity);
        const capital = draft.coreInfo.capital;
        const equityPerPerson =
          capital === '' ? 0 : Math.floor(Number(capital) / ownerIds.length);
        ownerIds.forEach(e => {
          draft.equity[e] = String(equityPerPerson);
        });
        draft.dirty = true;
        return;
      }

      case getType(actions.createNew): {
        Object.assign(draft, makeInitialState(action.payload));
        return;
      }

      case getType(actions.setOwnershipType): {
        draft.ownershipType = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.setFormOwner): {
        draft.formOwner = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.setDirty): {
        draft.dirty = action.payload;
        return;
      }

      case getType(actions.setEditableBoard): {
        draft.hasEditableBoard = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.clearRoles): {
        draft.roles = [];
        draft.dirty = true;
        return;
      }

      case getType(actions.setCompanyPurpose): {
        const { purpose, activityId, categoryId, verbosePurpose } =
          action.payload;
        draft.coreInfo.purpose = purpose;
        draft.coreInfo.verbosePurpose = verbosePurpose;
        draft.coreInfo.activityId = activityId;
        draft.coreInfo.activityCategoryId = categoryId;
        draft.coreInfo.companyType = getCompanyType(activityId);
        draft.dirty = true;
        return;
      }

      case getType(actions.resetCompanyPurpose): {
        draft.coreInfo.purpose = '';
        draft.coreInfo.verbosePurpose = '';
        draft.coreInfo.activityId = '';
        draft.coreInfo.activityCategoryId = '';
        draft.coreInfo.companyType = 'UNSPECIFIED';
        draft.dirty = true;
        return;
      }

      case getType(actions.setFormOwnerAsAddress): {
        draft.formOwnerAsAddress = action.payload;
        if (draft.formOwnerAsAddress) {
          const person = draft.people[draft.formOwner];
          Object.assign(draft.coreInfo, {
            address: person.address,
            postalCode: person.postalCode,
            cadasterAddress: person.cadasterAddress,
          });
        } else {
          draft.coreInfo.cadasterAddress = undefined;
          draft.coreInfo.address = '';
          draft.coreInfo.postalCode = '';
        }
        draft.dirty = true;
        return;
      }

      case getType(actions.markPageForValidation): {
        if (!draft.pagesMarkedForValidation.includes(action.payload)) {
          draft.pagesMarkedForValidation.push(action.payload);
          draft.dirty = true;
        }
        return;
      }

      case getType(actions.setMarketingConsent): {
        draft.marketingConsent = action.payload;
        draft.dirty = true;
        return;
      }

      case getType(actions.setOrgTransfer): {
        const { orgId, orgName, signatory } = action.payload;
        // the id is blank for a person that is being created, rather than one
        // reused from the people array.
        const personId = signatory.id === '' ? uuid.v1() : signatory.id;

        setPersonInfo(draft, { ...signatory, id: personId });

        draft.coreInfo.orgTransfer = {
          orgId,
          orgName,
          signatory: personId,
        };
        draft.dirty = true;
        return;
      }

      case getType(actions.clearOrgTransfer): {
        draft.coreInfo.orgTransfer = undefined;
        draft.dirty = true;
        return;
      }
    }
  },
  makeInitialState(),
);

function doRemoveRole(roles: RoleAssignment[], id: string, role: Role) {
  return roles.filter(e => !(e.id === id && e.role === role));
}

function doAddRole(roles: RoleAssignment[], id: string, role: Role) {
  const newRoles = roles.slice();
  newRoles.push({ id, role });
  return newRoles;
}

// fixme: rename this when we move actions into separate module
// fixme: given that we use immer, we can refactor this persumably?
function performAssignRole(roles: RoleAssignment[], id: string, role: Role) {
  if (role === 'chair') {
    const chair = roles.find(roleAssignment => roleAssignment.role === 'chair');
    // If a chair exists, demote to boardMember
    if (chair) {
      roles = removeChair(roles);
      roles = doAddRole(roles, chair.id, 'boardMember');
    }
    roles = doRemoveRole(roles, id, 'deputy');
    roles = doRemoveRole(roles, id, 'boardMember');
  } else if (role === 'deputy') {
    roles = doRemoveRole(roles, id, 'deputy');
    roles = doRemoveRole(roles, id, 'boardMember');
    roles = doRemoveRole(roles, id, 'chair');
  } else if (role === 'boardMember') {
    roles = doRemoveRole(roles, id, 'deputy');
    roles = doRemoveRole(roles, id, 'boardMember');
    roles = doRemoveRole(roles, id, 'chair');
  }
  roles = doAddRole(roles, id, role);
  return roles;
}

function removeChair(roles: RoleAssignment[]) {
  return roles.filter(e => e.role !== 'chair');
}

export { reducer };
